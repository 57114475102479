import React, {Fragment, useState, useEffect, useContext} from 'react';
import { Typography, Stack, Drawer, Grid, Box, AppBar } from '@mui/material';
import Container from '@mui/material/Container';
import Link from 'next/link';
import Image from 'next/image';
import NavMenuOne from './menus/NavMenuOne';
import NavMenuTwo from './menus/NavMenuTwo';
import PageLoader from '@/components/loader';
import { useAppContext } from '../../AppContext';
import { useTheme } from '@mui/material/styles';
import Head from 'next/head';

const renderNavMenu = (type, menu) => {
  switch(type){
    case 1: return <NavMenuOne data={menu}/>;
    case 2: return <NavMenuTwo data={menu}/>;
    default: return <NavMenuTwo data={menu}/>;
  };
};

function NavbarZero(props) {
  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();
  const [themeCss, setThemeCss] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    let custom_css = themeData && themeData ? JSON.parse(themeData && themeData.custom_css): null;
    setThemeCss(custom_css);
    setLoading(false)
  }, [themeData]);


  const bannerContainer = {
    background:theme.palette.background.header,
    width:'100%',
    '& a':{
      fontFamily: themeCss ? (themeCss.theme_body_font).replace("+"," ") : '',
      color: `${theme.palette.primary.contrastText} !important`,
  },
  }

  return (
    <>
      <Head>
        <link rel="icon" type="image/x-icon" href={siteData && siteData ? siteData.club_inner[0].logo_path : ""} />
      </Head>
      <AppBar position="sticky" sx={{boxShadow: theme.shadows[0]}}>
        {loading ? ( <PageLoader /> ) : (
        <Typography component="div" sx={bannerContainer}>
          <Container maxWidth="lg" >
            <Stack diaplay="flex" flexDirection={"row"} justifyContent={'space-between'}>
                <Link href={"/"}>
                  <Image
                    src={siteData && siteData ? siteData.club_inner[0].logo_path : ""}
                    alt="..."
                    width={100}
                    height={55}
                    sizes="(max-width: 325px) 100vw"
                    style={{objectFit: "contain",mt:1}}	
                  />
                </Link>
                <Typography component="div" className="desktopMode">
                  {renderNavMenu(2)}
                </Typography>
                <Typography component="div" className="mobileMode">
                  {renderNavMenu(1)}
                </Typography>
            </Stack>
          </Container>    
        </Typography>
        )}        
      </AppBar>
    </>
  )
}

export default NavbarZero