import React, {Fragment, useState, useEffect} from 'react';
import { Typography, Stack} from '@mui/material';
import Link from 'next/link';
import {useRouter} from 'next/router'
import { useAppContext } from '../../../AppContext';
import { useTheme } from '@mui/material/styles';


function NavMenuOne() {
  let router = useRouter();
  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();
  const [themeCss, setThemeCss] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    let custom_css = themeData && themeData ? JSON.parse(themeData && themeData.custom_css): null;
    setThemeCss(custom_css);
    setLoading(false)
  }, [themeData]);

const menuContainerTwo = {
    display:'flex',
    alignItems:'flex-start',
    textAlign:'right',
    // mt:2,
    '& a':{
        fontFamily: themeCss ? themeCss.theme_body_font.replace("+"," ") : '',
        fontWeight:500,
        ml:5,
        transition:`0.75s` ,
        fontSize:18,
        color: `#efefef`,
        '&:hover':{
            transition:`0.75s` ,
            color: themeCss ? themeCss.theme_main_color: ``,
        },        
    },
    '& .active':{
      transition:`0.75s` ,
      color: themeCss ? themeCss.theme_main_color: ``,
    },
}

  return (
    <Fragment>
      {siteData&&siteData.club_inner[0].events_only == 0 ? (
        <Typography component="div" sx={menuContainerTwo}>    
            <Stack display="flex" justifyContent='space-between' flexDirection='row' alignItems='flex-start' sx={{mt:2}}>
            {siteData && siteData.sections[0].menus.split(",").indexOf("menu1") >= 0 && (
                <Link href='/about' className={router.pathname == '/about' ? "active" : ''}>About Us</Link>
              )}
              {siteData && siteData.sections[0].menus.split(",").indexOf("menu2") >= 0 && (
                <Link href='/events'className={router.pathname == '/events' || router.pathname == '/event' ? "active" : ''}>Events</Link>
              )}
              {siteData && siteData.sections[0].menus.split(",").indexOf("menu7") >= 0 && (
                <Link href='/deals'className={router.pathname == '/deals' || router.pathname == '/deal' ? "active" : ''}>Deals</Link>
              )}
              {siteData && siteData.sections[0].menus.split(",").indexOf("menu6") >= 0 && (
                <Link href='/menus'className={router.pathname == '/menus' ? "active" : ''}>Menus</Link>
              )}
              {siteData && siteData.sections[0].menus.split(",").indexOf("menu8") >= 0 && (
                <Link href='/gallery'className={router.pathname == '/gallery' ? "active" : ''}>Gallery</Link>
              )}
              {siteData && siteData.sections[0].menus.split(",").indexOf("menu3") >= 0 && (
                <Link href='/tables'className={router.pathname == '/tables' ? "active" : ''}>Tables</Link>
              )}
              {siteData && siteData.sections[0].menus.split(",").indexOf("menu4") >= 0 && (
                <Link href='/shop' className={router.pathname == '/' ? "active" : ''}>Merchandise</Link>
              )}
              {siteData && siteData.sections[0].menus.split(",").indexOf("menu5") >= 0 && (
                <Link href='/contact'className={router.pathname == '/contact' ? "active" : ''}>Contact Us</Link>
              )}
            </Stack>
        </Typography>
      ):('')}
    </Fragment>
  )
}

export default NavMenuOne