import React, {Fragment, useState, useEffect } from 'react';
import Hamburger from 'hamburger-react'
import { Typography, Stack, Drawer, Grid, Box } from '@mui/material';
import Link from 'next/link';

import {useRouter} from 'next/router';
import { useAppContext } from '../../../AppContext';
import { useTheme } from '@mui/material/styles';

function NavMenuOne() {
  let router = useRouter();
  const [isOpen, setOpen] = useState(false);
  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();
  const [themeCss, setThemeCss] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    let custom_css = themeData && themeData ? JSON.parse(themeData && themeData.custom_css): null;
    setThemeCss(custom_css);
    setLoading(false)
  }, [themeData]);


  const muiDrawer = {  
    '& .MuiDrawer-paper': {
      background: theme.palette.background.alternate,
      borderLeft:`1px solid #red`,
      maxWidth: '33%',
      width: '33%',
      opacity: 0.8,
      paddingBottom: 12,
      [theme.breakpoints.down("md")]: {
        maxWidth: '80%',
        width: '80%',
      },
    }
  };
  
  const HamburgerStyle = {
    '& .hamburger-react':{
      mt:1,
      background: themeCss ? themeCss.theme_main_color: `#212121`,
      padding:'6px',
      borderRadius:50,
      color: themeCss ? themeCss.theme_primary_font_color: `#212121`,
      fontFamily: themeCss ? themeCss.theme_body_font.replace("+"," ") : '',
      zIndex:9999
    }
  }
  const menuContainer = {
    height:'100vh',
    mt:15,
    ml:11,
    display:'flex',
    alignItems:'start',
    flexDirection:'column',
    justifyContent:'center',
    textAlign:'left',
    color: theme.palette.primary.contrastText,
    '& a':{
      fontFamily: themeCss ? themeCss.theme_body_font.replace("+"," ") : '',
      color: theme.palette.primary.contrastText,
      fontSize:22,
      fontWeight:500,
      mb:3.5,
      display:'block',
      textAlign:'left',
      transition:'0.5s',
      textTransform:'capitalize',
      '&:hover':{
        color: themeCss ? themeCss.theme_main_color:'',
        transition:'0.5s',
      }
    },
    '& .active':{
      transition:`0.75s` ,
      color: themeCss ? themeCss.theme_main_color: ``,
    },
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }


  return (
    <Fragment>
      {siteData&&siteData.club_inner[0].events_only == 0 ? (
      <Typography component="div" sx={isOpen ? {marginRight:-10, ...HamburgerStyle} : {...HamburgerStyle}} className='desktopMode'>
        <Hamburger toggled={isOpen} toggle={setOpen} />
      </Typography>
      ):('')}
      {siteData&&siteData.club_inner[0].events_only == 0 ? (
      <Typography component="div" sx={HamburgerStyle} className='mobileMode'>
        <Hamburger toggled={isOpen} toggle={setOpen} />
      </Typography>
      ):('')}
  
      < Drawer
        anchor="right"
        open={isOpen}
        onClose={() => null}
        ModalProps={{
            keepMounted: false,
        }}
        hideBackdrop={false}         
        sx={{
            ...muiDrawer,
            backdropFilter: "blur(5px)",
        }}
        onEscapeKeyDown={handleDrawerClose}
        onBackdropClick={handleDrawerClose}
      >
        <Typography component="div" sx={menuContainer}>
        {siteData && siteData.sections[0].menus.split(",").indexOf("menu1") >= 0 && (
          <Link href='/about' className={router.pathname == '/about' ? "active" : ''}>About Us</Link>
        )}
        {siteData && siteData.sections[0].menus.split(",").indexOf("menu2") >= 0 && (
          <Link href='/events' className={router.pathname == '/events' || router.pathname == '/event'  ? "active" : ''}>Events</Link>
        )}
        {siteData && siteData.sections[0].menus.split(",").indexOf("menu7") >= 0 && (
          <Link href='/deals' className={router.pathname == '/deals' || router.pathname == '/deal' ? "active" : ''}>Deals</Link>
        )}
        {siteData && siteData.sections[0].menus.split(",").indexOf("menu6") >= 0 && (
          <Link href='/menus' className={router.pathname == '/menus' ? "active" : ''}>Menu</Link>
        )}
        {siteData && siteData.sections[0].menus.split(",").indexOf("menu8") >= 0 && (
          <Link href='/gallery' className={router.pathname == '/gallery' ? "active" : ''}>Gallery</Link>
        )}
        {siteData && siteData.sections[0].menus.split(",").indexOf("menu3") >= 0 && (
          <Link href='/tables' className={router.pathname == '/tables' ? "active" : ''}>Tables</Link>
        )}
        {siteData && siteData.sections[0].menus.split(",").indexOf("menu4") >= 0 && (
          <Link href='/shop' className={router.pathname == '/shop' ? "active" : ''}>Merchandise</Link>
        )}
        {siteData && siteData.sections[0].menus.split(",").indexOf("menu5") >= 0 && (
          <Link href='/contact' className={router.pathname == '/contact' ? "active" : ''}>Contact Us</Link>
        )}
        </Typography>
      </Drawer>
    </Fragment>
  )
}

export default NavMenuOne