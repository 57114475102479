import React, { useState, useEffect, useContext } from "react";
import FooterOne from "@/components/footers/TypeOne";
import FooterTwo from "@/components/footers/TypeTwo";
import { useAppContext } from '../../AppContext';
import { useTheme } from '@mui/material/styles';


const renderFooter = (type) => {
  switch (type) {
    case 1:
      return <FooterOne />;
    case 2:
      return <FooterTwo />;
    default:
      return <FooterOne />;
  }
};

export default function Footer() {
  const theme = useTheme();
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();
  const [themeCss, setThemeCss] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    let custom_css = themeData && themeData ? JSON.parse(themeData && themeData.custom_css): null;
    setThemeCss(custom_css)
    setLoading(false)
  }, [themeData]);
  
  
  return (
    <>
      {!loading && renderFooter(siteData && siteData.sections[0].footer_type)}
    </>
  );
}
